import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import VCardComponent from "../../components/vcard/vcard"

import employees from "../../assets/data/employees.json"

const VCard = () => {
    const navigator = useNavigate()
    const params = new URLSearchParams(document.location.search);

    const [employeeId, setEmployeeId] = useState(params.get("tc"));
    const [employee, setEmployee] = useState();

    useEffect(() => {
        if (!employeeId) {
            navigator('/')
        }else {
            const e = employees.find((e)=> {
                return e.employee_id === employeeId
            })

            if (!e) {
                navigator('/')
            }else {
                console.log(e)
                setEmployee(e)
            }
        }
      }, [employeeId, navigator]);

    return (
        <VCardComponent {...employee} />
    );
}

export default VCard;