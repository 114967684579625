import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="hero">
      <div className="container relative z-20">
        <div className="text-center">
          <h1 className="text-6xl text-white font-extrabold mb-3">
            Track Your Digital Identity.
          </h1>
          <h3 className="text-3xl text-white mb-10">
            Action For Migration -  Refugee Digital Identity is smart, elegant and easy solution to track your resettlement or family re-unification application 
          </h3>

          

          <div className="flex justify-center items-center gap-2 mt10 mb-10">
            <Link
              to="/#"
              className=" text-white border-2 hover:bg-white hover:text-[#059cd3] font-medium border-white rounded-full px-10 py-2"
            >
              Track Your Application Now
            </Link>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
