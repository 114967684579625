import React from "react";
import Welcome from "../../components/homepage-components/welcome";
import HowItWorks from "../../components/homepage-components/how-it-works";
import Features from "../../components/homepage-components/features";
import Footer from '../../components/footer/footer'
import Contact from "../../components/homepage-components/contact";

const Home = () => {
  return (
    <div>
      <Welcome />
      <HowItWorks />
      <Features/>
      <Contact/>
      <Footer/>
    </div>
  );
};
export default Home;
