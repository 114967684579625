import React from "react";

import telephone from "../../assets/image/telephone.svg";
import envelope from "../../assets/image/envelope.svg"
import geo from "../../assets/image/geo-alt.svg"
import globe from "../../assets/image/globe.svg"
import id from "../../assets/image/icons8-id-24.png"

const VCardComponent = (props) => {
    const {
        name,
        position,
        organisation,
        id_no,
        employee_id,
        phone,
        email,
        address,
        website,
        profile_photo
    } = props
    return (
        <div className="py-5">
            <div className="container">
                <div className="md:w-8/12 w-full mx-auto">
                    <div className="bg-white rounded">
                        <div className="flex align-items-center p-3 overflow-hidden text-break rounded" style={{background: "#059cd3"}}>
                            <div className="me-3">
                                <img className="rounded-circle" src={`${profile_photo}`} width="101" height="101" alt=""/>
                            </div>

                            <div>
                                <div className="font-bold fs-8" style={{color: "rgb(255, 255, 255)"}}>
                                    {name}
                                </div>
                                <div className="text-sm mt-1" style={{color: "rgb(255, 255, 255)"}}>
                                    {position}
                                </div>
                                <div className="text-sm mt-1" style={{color: "rgb(255, 255, 255)"}}>
                                    {organisation}
                                </div>
                                
                            </div>
                        </div>
                        <div className="overflow-hidden p-3 text-break">

                            <div className="flex text-gray text-sm mt-3">
                                <img src={id} width="11" height="11" className="me-2 mb-1" alt=""/>
                                National ID
                            </div>
                            <div className="mb-1"><a className="text-decoration-none" href="/#">{id_no}</a></div>

                            <div className="flex text-gray text-sm mt-3">
                                <img src={id} width="11" height="11" className="me-2 mb-1" alt=""/>
                                Employee ID
                            </div>
                            <div className="mb-1"><a className="text-decoration-none" href="/#">{employee_id}</a></div>

                            <div className="flex text-gray text-sm mt-3">
                                <img src={telephone} width="12" height="12" className="me-2 mb-1" alt=""/>
                                Phone
                            </div>
                            <div className="mb-1"><a className="text-decoration-none" href={`tel:${phone}`}>{phone}</a></div>

                            <div className="flex text-gray text-sm mt-3">
                                <img src={envelope} width="12" height="12" className="me-2 mb-1" alt=""/>
                                Email
                            </div>
                            <div className="mb-1"><a className="text-decoration-none" href={`mailto:${email}`}>{email}</a></div>

                            <div className="flex text-gray text-sm mt-3">
                                <img src={geo} width="12" height="12" className="me-2 mb-1" alt=""/>
                                Address
                            </div>
                            <div>{address}</div>


                            <div className="flex text-gray text-sm mt-3">
                                <img src={globe} width="12" height="12" className="me-2 mb-1" alt=""/>
                                Website
                            </div>
                            <div className="mb-1"><a className="text-decoration-none" href={`${website}`}>{website}</a></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default VCardComponent